.letter {
    animation: fadeIn 7s ease-in-out 1 forwards;
}

.sentence {
    animation: slide 7s linear 1 forwards;
    transform-origin: bottom left;
}

@keyframes slide {
  0% {
    transform: translateX(10%);
    animation-timing-function: ease-out;
  }
  8% {
    transform: translateX(0);
    animation-timing-function: ease-in;
  }
  100% {
    transform: translateX(2%);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 1;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}