:root {
  --anim-repeat-mode: 1;
}



.container-animation-trivia {
    position: relative;
    width: 600px;
    height: 600px;
    /* overflow: hidden; */
    background-color: #d1181800;
  }

.holder {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 50%;
  height: 50%;
}

.base {
  transform-origin: center;
  width: 100%;
  height: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.c_phone {
  scale: 1.5;
  background-image: url("./assets/c_trivia_phone.png");
  animation: c_phone_anim 7s ease-in-out var(--anim-repeat-mode);
  image-rendering: optimizeQuality;
  animation-fill-mode: both;
  font-size: 0.9em;
}

.c_trivia_bg {
  background-image: url("./assets/c_trivia_bg_opt.png");
  animation: c_trivia_bg_anim 7s ease-in-out var(--anim-repeat-mode);
  animation-fill-mode: both;
}

.c_trivia_question {
  color: rgb(227, 107, 16);
  font-size: 80%;
  font-weight: bold;
  text-align: center;
  transform: rotate(-9deg) skew(6deg);
}

.c_question_typing {
  clip-path: inset(0 100% 0 0);
  animation: c_question_typing 0.7s linear var(--anim-repeat-mode);
  animation-fill-mode: both;
  text-rendering: optimizelegibility;
}

.c_trivia_answer {
  color: rgb(130, 232, 255);
  font-family: Arial;
  font-size: 70%;
  font-weight: bold;
  text-align: center;
  transform: rotate(-11deg) skew(5deg);
}

.c_trivia_item_1 {
  background-image: url("./assets/c_trivia_play.png");
  animation: c_trivia_item_1 7s ease-in-out var(--anim-repeat-mode);
  animation-fill-mode: both;
}

.c_trivia_item_2 {
  transform: scale(0);
  background-image: url("./assets/c_trivia_ai.png");
  animation: c_trivia_item_2 7s ease-in-out var(--anim-repeat-mode);
  animation-fill-mode: both;
}

.c_trivia_item_3 {
  transform: scale(0);
  background-image: url("./assets/c_trivia_options.png");
  animation: c_trivia_item_3 7s ease-in-out var(--anim-repeat-mode);
  animation-fill-mode: both;
}

@keyframes c_phone_anim {
  0% {
    transform: translate(0, -200%) rotate(7deg) skewY(-5deg) rotate(-7deg);
    animation-timing-function: cubic-bezier(0, 0.86, 0.15, 0.99);
  }
  10% {
    transform: rotate(15deg) skewY(-8deg) rotate(-15deg);
    animation-timing-function: linear;
  }
  90% {
    transform: translate(0%, 0%);
    animation-timing-function: cubic-bezier(0.61, 0, 0.89, 0.45);
  }
  100% {
    transform: translate(0, -250%);
  }
}

@keyframes c_trivia_bg_anim {
  0% {
    transform: translate(0, -200%);
    animation-timing-function: cubic-bezier(0, 0.86, 0.15, 0.99);
  }
  10% {
    transform: translate(-10%, 0);
    animation-timing-function: linear;
  }
  100% {
  }
}

@keyframes c_trivia_item_1 {
    0% {
      transform: scale(0);
    }
    10% {
      transform: scale(0);
      animation-timing-function: cubic-bezier(.3,1.5,.64,.95);
    }
    17% {
      transform: scale(1);
      animation-timing-function: linear;
    }
    90% {
      transform: scale(1) translate(10%, 0%);
      animation-timing-function: cubic-bezier(.61,0,.89,.45);   
    }
    100% {
      transform: scale(1) translate(10%, -700%);
    }
  }

  @keyframes c_trivia_item_2 {
    0% {
      transform: scale(0);
    }
    12% {
      transform: scale(0);
      animation-timing-function: cubic-bezier(.3,1.5,.64,.95);
    }
    19% {
      transform: scale(1);
      animation-timing-function: linear;
    }
    90% {
      transform: scale(1) translate(20%, 0%) skew(-3deg, 5deg);
      animation-timing-function: cubic-bezier(.61,0,.89,.45);   
    }
    100% {
      transform: scale(1) translate(20%, 500%) skew(-3deg, 5deg);
    }
  }
  
  @keyframes c_trivia_item_3 {
    0% {
      transform: scale(0);
    }
    15% {
      transform: scale(0);
      animation-timing-function: cubic-bezier(.3,1.5,.64,.95);
    }
    23% {
      transform: scale(1);
      animation-timing-function: linear;
    }
    90% {
      transform: scale(1) translate(-5%, 0%) skew(3deg, -5deg);
      animation-timing-function: cubic-bezier(.61,0,.89,.45);   
    }
    100% {
      transform: scale(1) translate(-5%, 850%) skew(3deg, -5deg);
    }
  }

@keyframes c_question_typing {
  0% {
    clip-path: inset(0 100% 0 0);
  }
  100% {
    clip-path: inset(0 0 0 0);
  }
}


@media (max-width: 768px) {
  .container-animation-trivia {
    width: 300px;
    height: 300px;
  }
  .c_trivia_question {
    font-size: 5px

  }
  
  .c_trivia_answer {

    font-size: 4px;

  }
  
}