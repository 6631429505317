.fade-text {
    transition: opacity 1s ease;
    color: #333;
    text-align: center;
  }
  
  .fade-in {
    opacity: 1;
  }
  
  .fade-out {
    opacity: 0;
  }
  