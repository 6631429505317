/* styles.module.css */
.main {
  display: flex;
  flex-direction: row;
  width: 100%;
}
.container {
  perspective: 5000px; /* Adds 3D perspective */
}


.char {
  display: inline-block;
  backface-visibility: hidden; /* Ensures the back face is hidden during the flip */
  transform-style: preserve-3d; /* Enables 3D transformations */
  margin: 0 0px; /* Adds spacing between characters */
}
@media (max-width: 768px) {
  .main {
    width: 100%;
    /* min-width: 90ch;
    max-width: 90ch; */
  }
}

@media (max-width: 480px) {
  .main {
    width: 100%;
    /* min-width: 70ch;
    max-width: 70ch; */
  }
}