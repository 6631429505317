*::after {
  transform-style: preserve-3d;
}
/* Flip container */
.carousel {
  perspective: 1000px;
}

.flip-box {
  width: 300px;
  height: 150px;
  position: relative;
}

.flip-box-inner {
  width: 100%;
  height: 100%;/* General styles */
  body {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background: #282c34;
    color: white;
    font-family: Arial, sans-serif;
  }
  
  /* Flip text container */
  .flip-text {
    display: flex;
    font-size: 2rem;
    gap: 5px;
    font-weight: bold;
  }
  
  /* Individual character animation */
  .char {
    display: inline-block;
    position: relative;
    perspective: 500px;
    transform-style: preserve-3d;
    animation: flipText 3s infinite ease-in-out;
  }
  
  /* Flipping animation */
  @keyframes flipText {
    0% {
      transform: rotateX(0deg);
    }
    50% {
      transform: rotateX(-90deg);
      opacity: 0;
    }
    51% {
      opacity: 0;
    }
    100% {
      transform: rotateX(0deg);
      opacity: 1;
    }
  }
  
  /* Staggered flipping effect */
  .char:nth-child(1) { animation-delay: 0s; }
  .char:nth-child(2) { animation-delay: 0.1s; }
  .char:nth-child(3) { animation-delay: 0.2s; }
  .char:nth-child(4) { animation-delay: 0.3s; }
  .char:nth-child(5) { animation-delay: 0.4s; }
  
  position: absolute;
  transform-style: preserve-3d;
  animation: flipAnimation 6s infinite linear;
}

/* Front and Back sides */
.flip-box-front,
.flip-box-back {
  width: 100%;
  height: 100%;
  position: absolute;
  backface-visibility: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
  text-align: center;
  background: #444;
  border-radius: 10px;
}

/* Back face (hidden initially) */
.flip-box-back {
  background: #666;
  transform: rotateY(180deg);
}

/* Flip animation */
@keyframes flipAnimation {
  0%,
  100% {
    transform: rotateY(0deg);
  }
  50% {
    transform: rotateY(180deg);
  }
}
