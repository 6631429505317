

.content-container {
    position: relative;
    z-index: 100;
    width: 100%;
    height: auto;
    text-align: center;
    
    /* max-width: 600px; */
    padding: 2rem;
}


 .header-top {
    z-index: 20000; /* Ensure it is above other elements */
    pointer-events: all
 }




.gradient-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    overflow: hidden;
}

.gradient-sphere {
    position: absolute;
    border-radius: 50%;
    filter: blur(60px);
}

.sphere-1 {
    width: 40vw;
    height: 40vw;
    background: linear-gradient(40deg, rgba(255, 0, 128, 0.8), rgba(255, 102, 0, 0.4));
    top: -10%;
    left: -10%;
    animation: float-1 15s ease-in-out infinite alternate;
}

.sphere-2 {
    width: 45vw;
    height: 45vw;
    background: linear-gradient(240deg, rgba(72, 0, 255, 0.8), rgba(0, 183, 255, 0.4));
    bottom: -20%;
    right: -10%;
    animation: float-2 18s ease-in-out infinite alternate;
}

.sphere-3 {
    width: 30vw;
    height: 30vw;
    background: linear-gradient(120deg, rgba(133, 89, 255, 0.5), rgba(98, 216, 249, 0.3));
    top: 60%;
    left: 20%;
    animation: float-3 20s ease-in-out infinite alternate;
}

.noise-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.05;
    z-index: 20;
    background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 200 200' xmlns='http://www.w3.org/2000/svg'%3E%3Cfilter id='noiseFilter'%3E%3CfeTurbulence type='fractalNoise' baseFrequency='0.65' numOctaves='3' stitchTiles='stitch'/%3E%3C/filter%3E%3Crect width='100%25' height='100%25' filter='url(%23noiseFilter)'/%3E%3C/svg%3E");
}

@keyframes float-1 {
    0% {
        transform: translate(0, 0) scale(1);
    }
    100% {
        transform: translate(10%, 10%) scale(1.1);
    }
}

@keyframes float-2 {
    0% {
        transform: translate(0, 0) scale(1);
    }
    100% {
        transform: translate(-10%, -5%) scale(1.15);
    }
}

@keyframes float-3 {
    0% {
        transform: translate(0, 0) scale(1);
        opacity: 0.3;
    }
    100% {
        transform: translate(-5%, 10%) scale(1.05);
        opacity: 0.6;
    }
}

.grid-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: 40px 40px;
    background-image: 
        linear-gradient(to right, rgba(255, 255, 255, 0.03) 1px, transparent 1px),
        linear-gradient(to bottom, rgba(255, 255, 255, 0.03) 1px, transparent 1px);
    z-index: 20;
}

.glow {
    position: absolute;
    width: 40vw;
    height: 40vh;
    background: radial-gradient(circle, rgba(72, 0, 255, 0.15), transparent 70%);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 20;
    animation: pulse 8s infinite alternate;
    filter: blur(30px);
}


@keyframes pulse {
    0% {
        opacity: 0.3;
        transform: translate(-50%, -50%) scale(0.9);
    }
    100% {
        opacity: 0.7;
        transform: translate(-50%, -50%) scale(1.1);
    }
}

.particles-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9998; /* Ensure it is above other elements */
    pointer-events: all
}

.particle {
    position: absolute;
    background: rgb(255, 255, 255);
    border-radius: 50%;
    opacity: 1;
    pointer-events: none;
    overflow: hidden;
}

.btn {
    z-index: 9999;
}


/* End of CSS file */


.fliper-text {
    /* display: inline-block; */
    animation: animate 3s infinite;
    animation-delay: calc(0.2s * var(--delay));
}
@keyframes  animate {
    0%, 80% {
      transform: rotateY(360deg);
    }
  }
