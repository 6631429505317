
  :root {
    --anim-repeat-mode: 1;
  }
  * {
    box-sizing: border-box;
    /* border-width: 1px; */
  }

  .outer-wrapper {
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vw;
    background-color: #f0f0f000;
  }

  .container-animation {
    position: relative;
    width: 600px;
    height: 600px;
    /* overflow: hidden; */
    background-color: #d1181800;
  }

  .holder {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 50%;
    height: 50%;
  }

  .phone {
    transform-origin: center;
    width: 71.9%;
    height: 100%;
    scale: 1.5;
    background-image: url("./assets/phone.png");
    background-size: cover;
    animation: phone_anim 7s ease-in-out var(--anim-repeat-mode);
    animation-fill-mode: both;
  }

  .trivia_bg {
    transform-origin: center;
    width: 100%;
    height: 100%;
    scale: 1.2;
    background-image: url("./assets/trivia_bg.png");
    background-size: contain;
    background-repeat: no-repeat;
    animation: trivia_bg_anim 6s ease-in-out var(--anim-repeat-mode);
    animation-fill-mode: both;
  }

  .trivia_ans_0 {
    transform-origin: center;
    width: 100%;
    height: 100%;
    scale: 0.6;
    transform: scale(0);
    background-image: url("./assets/trivia_ans.png");
    background-size: contain;
    background-repeat: no-repeat;
    animation: trivia_bg_anim 6s cubic-bezier(.57,1.48,.83,.9) var(--anim-repeat-mode);
    animation-fill-mode: both;
  }
  .trivia_ans_1 {
    transform-origin: center;
    width: 100%;
    height: 100%;
    scale: 0.6;
    transform: scale(0);
    background-image: url("./assets/trivia_ans_1.png");
    background-size: contain;
    background-repeat: no-repeat;
    animation: trivia_bg_anim 6s cubic-bezier(.57,1.48,.83,.9) var(--anim-repeat-mode);
    animation-fill-mode: both;
  }
  .trivia_ans_2 {
    transform-origin: center;
    width: 100%;
    height: 100%;
    scale: 0.6;
    transform: scale(0);
    background-image: url("./assets/trivia_ans_2.png");
    background-size: contain;
    background-repeat: no-repeat;
    animation: trivia_bg_anim 6s cubic-bezier(.57,1.48,.83,.9) var(--anim-repeat-mode);
    animation-fill-mode: both;
  }

  .trivia_ans_3 {
    transform-origin: center;
    width: 100%;
    height: 100%;
    scale: 0.6;
    transform: scale(0);
    background-image: url("./assets/trivia_ans_3.png");
    background-size: contain;
    background-repeat: no-repeat;
    animation: trivia_bg_anim 6s cubic-bezier(.57,1.48,.83,.9) var(--anim-repeat-mode);
    animation-fill-mode: both;
  }


  .trivia_usr_ans_bg {
    transform-origin: 0% 70%;
    width: 100%;
    height: 100%;
    transform: scale(0);
    background-size: contain;
    background-repeat: no-repeat;
    animation: trivia_bg_anim 7s cubic-bezier(.57,1.48,.83,.9) var(--anim-repeat-mode);
    animation-fill-mode: both;
  }

  .trivia_usr_ans_bg_blurred {
    filter: blur(3px);
    z-index: -1;
  }

  @keyframes phone_anim {
    0% {
      transform: translate(50%, 200%) rotate(95deg);
      animation-timing-function: cubic-bezier(.17,1.17,.43,1.01);
    }
    20% {
      transform: translate(-8%, 0) rotate(0deg);
      animation-timing-function: linear;
    }
    90% {
      transform: translate(-16%, 0);
      animation-timing-function: cubic-bezier(.63,0,.84,.6);
    }
    100% {
      transform: translate(6%, 300%) rotate(-30deg);
    }
  }

  @keyframes trivia_bg_anim {
    0% { transform: scale(0); }
    10% { transform: scale(0); }
    17% { transform: scale(1); }
    100% { transform: scale(1); }
  }


  @media (max-width: 768px) {
    .container-animation {
      width: 300px;
      height: 300px;
    }

    .phone {
      scale: 1.2;
    }

    .trivia_bg {
      scale: 1;
    }

    .trivia_ans_0,
    .trivia_ans_1,
    .trivia_ans_2,
    .trivia_ans_3 {
      scale: 0.5;
    }

    .trivia_usr_ans_bg {
      transform-origin: 0% 50%;
    }
  }